import {Resolution} from "../../../common/resolution";
import {IMAGE_BREAKPOINTS} from "../../../common/resolutionConstants";
import {Cookieflag} from "../../../common/cookieflag";
import {isPresent} from "../../../common/utils/basics";
import {resolve} from "../../../container";
import {eopCustomEvent, EventBusEvent} from "../../../common/eventBus";
import {parseResolutionSource} from "./imageUtils";
import {ResolutionSource} from "./image";
import {clamp} from "../../../bootstrap/common/numbers";
import {customElement, property, query} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {COLOR_SCHEME_CHANGED_EVENT, type ColorSchemeData} from "../../../common/colorSchemeService";
import {DarkModeImage} from "./darkModeImage";

const IMAGE_TOO_WIDE_FOR_PARENT_CLASS = "image-too-wide-for-parent";
const LANDSCAPE_IMAGE_TOO_TALL_FOR_PARENT_CLASS = "landscape-image-too-tall-for-parent";
const PORTRAIT_IMAGE_CLASS = "portrait-image";
const LANDSCAPE_IMAGE_CLASS = "landscape-image";

@customElement("eop-plain-image")
export class EopPlainImage extends DarkModeImage {

    @property({attribute: "alt"})
    public alt: string;
    @property({attribute: "reverse-src-order"})
    public reverseSrcOrder: boolean = false;
    @property({attribute: "out-of-view", type: Boolean, reflect: true})
    public outOfView: boolean = false;
    @query(".plain-image")
    private imageElement: HTMLImageElement;

    public constructor(
        private cookieflag: Cookieflag = resolve(Cookieflag),
        private resolution: Resolution = resolve(Resolution)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        if (this.cookieflag.isSetFor("enbw-disable-responsive-image")) {
            return;  // used for layout tests
        }

        if (this.srcDark) {
            this.eventBus.on(COLOR_SCHEME_CHANGED_EVENT, (_: EventBusEvent<ColorSchemeData>) => {
                this.updateImage(this.srcCurrent ?? "", this.resolution.getBreakpoint(), this.reverseSrcOrder);
            });
        }
        this.updateImage(this.srcCurrent, this.resolution.getBreakpoint(), this.reverseSrcOrder);

        this.resolution.onWindowResize(breakpoint => this.updateImage(this.srcCurrent, breakpoint, this.reverseSrcOrder), this);
    }

    public render(): TemplateResult {
        return html`<img src=${this.outOfView ? "" : this.srcCurrent}
                         alt=${this.alt ?? ""}
                         loading="lazy"
                         @load=${{handleEvent: () => this.imageLoaded(this.srcCurrent), once: true}}
                         class="plain-image"
                         ?baseplate=${this.enableBaseplate}/>`;
    }

    private updateImage(newSrc: string, newBreakpoint: number, useReverseSrcOrder: boolean = false): void {
        let breakpointSource: string | null;
        if (useReverseSrcOrder) {
            breakpointSource = Object.keys(IMAGE_BREAKPOINTS)
                .map(key => IMAGE_BREAKPOINTS[key])
                .filter(breakpoint => newBreakpoint < breakpoint.min)
                .map(breakpoint => this.getAttribute("src-" + breakpoint.min))
                .filter(isPresent)
                .first();
        } else {
            breakpointSource = Object.keys(IMAGE_BREAKPOINTS)
                .map(key => IMAGE_BREAKPOINTS[key])
                .filter(breakpoint => breakpoint.min <= newBreakpoint)
                .map(breakpoint => this.getAttribute("src-" + breakpoint.min))
                .filter(isPresent)
                .last();
        }

        this.srcCurrent = breakpointSource ?? newSrc;
    }

    private imageLoaded(src: string): void {
        const focusData = parseResolutionSource(src) ?? new ResolutionSource();

        const parentWidth = this.outerWidth();
        const parentHeight = this.outerHeight();
        const imageWidth = this.imageElement.outerWidth();
        const imageHeight = this.imageElement.outerHeight();
        const parentAspectRatio = parentWidth / parentHeight;
        const aspectRatio = imageWidth / imageHeight;
        let focusTranslateX = 50;
        let focusTranslateY = 50;

        this.imageElement.classList.remove(IMAGE_TOO_WIDE_FOR_PARENT_CLASS);
        this.imageElement.classList.remove(LANDSCAPE_IMAGE_TOO_TALL_FOR_PARENT_CLASS);

        if (aspectRatio > parentAspectRatio) {
            focusTranslateX = focusData.focusX ?? focusTranslateX;
            focusTranslateX = clamp(focusTranslateX, 100 * parentAspectRatio / (2 * aspectRatio), 100 * (1 - parentAspectRatio / (2 * aspectRatio)));
            this.imageElement.classList.add(IMAGE_TOO_WIDE_FOR_PARENT_CLASS);
        }
        if (aspectRatio < parentAspectRatio) {
            focusTranslateY = focusData.focusY ?? focusTranslateY;
            focusTranslateY = clamp(focusTranslateY, 100 * aspectRatio / (2 * parentAspectRatio), 100 * (1 - aspectRatio / (2 * parentAspectRatio)));
            if (aspectRatio > 1) {
                this.imageElement.classList.add(LANDSCAPE_IMAGE_TOO_TALL_FOR_PARENT_CLASS);
            }
        }
        this.imageElement.style.setProperty("--focus-translate", `-${focusTranslateX}% -${focusTranslateY}%`);

        if (aspectRatio <= 1) {
            this.imageElement.classList.add(PORTRAIT_IMAGE_CLASS);
        } else if (aspectRatio > 1) {
            this.imageElement.classList.add(LANDSCAPE_IMAGE_CLASS);
        }

        this.dispatchEvent(eopCustomEvent("imageLoaded"));
    }
}