import {html, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import {DarkModeImage} from "./image/darkModeImage";

@customElement("eop-static-svg")
export class EopStaticSvg extends DarkModeImage {

    @property({attribute: "alt"})
    private alt: string;

    public render(): TemplateResult {
        return html`<img src=${this.srcCurrent} alt=${this.alt} loading="lazy" class="svg-image" ?baseplate=${this.enableBaseplate}/>`;
    }
}