import {Visibility} from "./visibility";
import {Page} from "./page";
import {GLOBAL} from "./globals";
import {autoRegister, resolve} from "../container";

import {Lifetime, lifetime} from "./lifetime";
import {prepareEvents} from "./utils/events";
import {EASE_IN_OUT_QUAD} from "./easingFunctions";
import {Deferred} from "./utils/promises";

export const ELEMENT_SCROLL_PADDING = 20;
export const DEFAULT_SCROLL_DURATION = 500;
export const MAX_SCROLL_DURATION = 2000;
export const MAX_PAGE_SCROLL_THRESHOLD = 10000;
export const DEFAULT_EASING_FUNCTION = EASE_IN_OUT_QUAD;

@autoRegister()
export class ScrollService {
    public constructor(
        private page: Page = resolve(Page),
        private visibility: Visibility = resolve(Visibility)
    ) {
    }

    public defaultVerticalArea(): VerticalScrollArea {
        return new VerticalScrollArea(this.page, this.visibility, GLOBAL.htmlElement(), false);
    }

    public defaultHorizontalArea(): HorizontalScrollArea {
        return new HorizontalScrollArea(GLOBAL.htmlElement(), false);
    }

    public enclosingVerticalArea(element: Node): VerticalScrollArea {
        const scrollableArea = element instanceof Element && element.closest<HTMLElement>(".vertically-scrollable-area");
        if (scrollableArea) {
            return new VerticalScrollArea(this.page, this.visibility, scrollableArea, true);
        } else {
            return this.defaultVerticalArea();
        }
    }

    public enclosingHorizontalArea(element: Node): HorizontalScrollArea {
        const scrollableArea = element instanceof Element && element.closest<HTMLElement>(".horizontally-scrollable-area");
        if (scrollableArea) {
            return new HorizontalScrollArea(scrollableArea, true);
        } else {
            return this.defaultHorizontalArea();
        }
    }

    public scrollToSection(sectionId: string): Promise<Element> {
        return this.scrollToElement(GLOBAL.document().querySelector(sectionId)!);
    }

    public scrollToElement(element: Element, negativeMargin?: number, minDuration?: number, maxDuration?: number, easingFunction?: (x: number) => number): Promise<Element> {
        return this.enclosingVerticalArea(element)
            .scrollToElement(element, negativeMargin, minDuration, maxDuration, easingFunction);
    }

    public scrollToElementHorizontallyCentered(element: Element, duration?: number): Promise<Element> {
        return this.enclosingHorizontalArea(element)
            .scrollToElementCentered(element, duration);
    }

    public scrollElementIntoViewport(element: Element, duration: number): Promise<Element> {
        return this.defaultVerticalArea()
            .scrollElementIntoViewport(element, duration);
    }

    // -10 is a workaround for Safari, which doesn't scroll to 0
    public scrollToTop(duration?: number): Promise<Element> {
        return this.defaultVerticalArea()
            .scrollTo(-10, duration);
    }

    public scrollRelative(scrollDistance: number, duration: number): Promise<Element> {
        return this.defaultVerticalArea()
            .scrollTo(this.page.getYScrollPosition() + scrollDistance, duration);
    }

}

abstract class ScrollArea {

    protected constructor(
        public area: HTMLElement
    ) {
    }

    protected abstract scrollLinearlyTo(position: number): void;

    protected abstract scrollPosition(): number;

    public async scrollTo(
        targetPosition: number,
        minDuration: number = DEFAULT_SCROLL_DURATION,
        maxDuration: number = MAX_SCROLL_DURATION,
        easeFn: (x: number) => number = DEFAULT_EASING_FUNCTION
    ): Promise<Element> {
        const deferred = new Deferred<Element>();
        const startPosition = this.scrollPosition();
        const scrollDistance = targetPosition - startPosition;
        const duration = minDuration + Math.min(Math.abs(scrollDistance), MAX_PAGE_SCROLL_THRESHOLD) / MAX_PAGE_SCROLL_THRESHOLD * (maxDuration - minDuration);
        let startTime: number;
        let requestId: number;

        const step = (timestamp: number): void => {
            if (!startTime) {
                startTime = timestamp;
            }

            const elapsedTime = timestamp - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            this.scrollLinearlyTo(startPosition + scrollDistance * easeFn(progress));

            if (elapsedTime <= duration) {
                requestId = requestAnimationFrame(step);
            } else {
                cancelAnimationFrame(requestId);
                deferred.resolve(this.area);

            }
        };
        requestId = requestAnimationFrame(step);
        return await deferred.promise;
    }
}

export class VerticalScrollArea extends ScrollArea {
    public constructor(
        private page: Page,
        private visibility: Visibility,
        public area: HTMLElement,
        private nestedScroll: boolean
    ) {
        super(area);
    }

    protected scrollLinearlyTo(position: number): void {
        this.area.scrollTo({top: position, behavior: "auto"});
    }

    protected scrollPosition(): number {
        return this.area.scrollTop;
    }

    public scrollToElement(element: Node, negativeMargin: number = 0, minDuration?: number, maxDuration?: number, easingFunction?: (x: number) => number): Promise<Element> {
        let scrollTarget = this.calculateElementPosition(element) - negativeMargin;
        const scrollMargin = element instanceof Element ? element.computedStyle().scrollMarginTop.toInt("VerticalScrollArea.scrollToElement(), element.computedStyle().scrollMarginTop") : 0; // TODO remove debugInfo asap - #3222308
        if (scrollMargin) {
            scrollTarget -= scrollMargin;
            return this.scrollTo(scrollTarget, minDuration, maxDuration, easingFunction);
        }
        if (this.nestedScroll) {
            return this.scrollTo(scrollTarget, minDuration, maxDuration, easingFunction);
        }
        scrollTarget += this.page.getViewportOffsetWhenScrolledTo(scrollTarget);
        return this.scrollTo(scrollTarget, minDuration, maxDuration, easingFunction);
    }

    private calculateElementPosition(element: Node): number {
        const areaYOffset = this.area.topOffset();
        const elementYOffset = element instanceof Element ? element.topOffset() : 0;
        const nestedScrollPosition = this.nestedScroll ? this.area.scrollTop : 0;
        return elementYOffset - areaYOffset + nestedScrollPosition;
    }

    public async scrollElementIntoViewport(element: Element, duration: number): Promise<Element> {
        if (element === undefined || !(element instanceof Element) || this.visibility.isVisibleInsideViewport(element)) {
            return element;
        }
        const elementClientRect = element.getBoundingClientRect();
        if (this.rectFitsInsideViewport(elementClientRect)) {
            const scrollTarget = elementClientRect.bottom + ELEMENT_SCROLL_PADDING - this.page.viewportHeight();
            return this.scrollTo(scrollTarget, duration);
        } else {
            return this.scrollToElement(element, ELEMENT_SCROLL_PADDING, duration);
        }
    }

    private rectFitsInsideViewport(elementClientRect: DOMRect): boolean {
        return elementClientRect.height + ELEMENT_SCROLL_PADDING * 2 < this.page.viewportHeight();
    }

}

export class HorizontalScrollArea extends ScrollArea {

    public constructor(
        public area: HTMLElement,
        private nestedScroll: boolean
    ) {
        super(area);
    }

    protected scrollLinearlyTo(position: number): void {
        this.area.scrollTo({left: position, behavior: "auto"});
    }

    protected scrollPosition(): number {
        return this.area.scrollLeft;
    }

    public scrollToElementCentered(element: Node, duration?: number): Promise<Element> {
        const elementPosition = this.calculateElementPosition(element);
        const targetScrollPosition = elementPosition - this.calculateCorrectionForCentering(element);

        return this.scrollTo(targetScrollPosition, duration);
    }

    private calculateElementPosition(element: Node): number {
        const areaXOffset = this.area.leftOffset();
        const elementXOffset = element instanceof Element ? element.leftOffset() : 0;
        const nestedScrollPosition = this.nestedScroll ? this.area.scrollLeft : 0;
        return elementXOffset - areaXOffset + nestedScrollPosition;
    }

    private calculateCorrectionForCentering(element: Node): number {
        const areaWidth = this.area.clientWidth;
        const elementWidth = element instanceof Element ? element.clientWidth : 0;
        if (!areaWidth || !elementWidth) {
            return 0;
        }

        return (areaWidth - elementWidth) / 2;
    }
}

@autoRegister()
export class ScrollEvents {

    private scrollableElements: (Element | Window)[];
    private scrollCallbacks: (() => void)[];

    public constructor() {
        this.scrollableElements = [GLOBAL.window()];
        this.scrollCallbacks = [];
    }

    public registerScrollableElement(element: Element | Window): void {
        this.scrollCallbacks.forEach(callback => this.listenToScrollOnElement(element, callback));
        this.scrollableElements.push(element);
    }

    public unregisterScrollableElement(element: Element | Window): void {
        this.stopListeningToScrollOnElement(element);
        this.scrollableElements.removeFirst(element);
    }

    public onScroll(callback: () => void): void {
        this.scrollableElements.forEach(element => this.listenToScrollOnElement(element, callback));
        this.scrollCallbacks.push(callback);
    }

    private listenToScrollOnElement(element: Element | Window, callback: () => void): void {
        prepareEvents(element)
            .boundTo(lifetime(element))
            .on("scroll", () => callback());
    }

    private stopListeningToScrollOnElement(element: Element | Window): void {
        lifetime(element).drop();
    }

}

export type ScrollDirectionCallback = (scrollDirection: ScrollDirection, scrollY: number) => void;

export enum ScrollDirection {
    SCROLL_UP = 1, SCROLL_DOWN = 2
}

export class ScrollDirectionListener {

    private readonly callbacks: ScrollDirectionCallback[];
    private lastScrollYPosition: number;

    private constructor(
        private minScrollDown: number,
        private minScrollUp: number,
        private lifeTime: Lifetime,
        private page: Page = resolve(Page)
    ) {
        this.callbacks = [];
        this.lastScrollYPosition = this.page.getYScrollPosition();
        prepareEvents(GLOBAL.document())
            .boundTo(this.lifeTime)
            .on("scroll", () => this.scrolling());
    }

    public static from(lifeTime: Lifetime, minScrollDown: number = 0, minScrollUp: number = 0): ScrollDirectionListener {
        return new ScrollDirectionListener(minScrollDown, minScrollUp, lifeTime);
    }

    public onScroll(callback: ScrollDirectionCallback): void {
        this.callbacks.push(callback);
    }

    public offScroll(callback: ScrollDirectionCallback): void {
        this.callbacks.removeAll(callback);
    }

    private scrolling(): void {
        const currentScrollY: number = this.page.getYScrollPosition();
        const direction = this.directionOf(currentScrollY);
        if (direction) {
            for (const callback of this.callbacks) {
                callback(direction, currentScrollY);
            }
            this.lastScrollYPosition = this.page.getYScrollPosition();
        }
    }

    private directionOf(currentScrollY: number): ScrollDirection | null {
        if (this.lastScrollYPosition < currentScrollY - this.minScrollDown) {
            return ScrollDirection.SCROLL_DOWN;
        }
        if (this.lastScrollYPosition > currentScrollY + this.minScrollUp || currentScrollY === 0) {
            return ScrollDirection.SCROLL_UP;
        }
        return null;
    }
}